import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Menu from '../components/menu';
import Layout from "../components/layout";

export default () => {
	return (
		<Layout>
			<div className="jumbotron">
        <Link to="/extras" className="closeX"><span>X</span></Link>
        <Menu localPath="/videos" />
        <h1>Videos</h1>
      </div>
      <div className="gridBox">        
        <div className="gridFraction" style={{ 'width': '320px', 'height': '240px' }}>
          <video controls poster="/data/videos/poster-beethoven5.png">
            <source src="/data/videos/Beethoven-5.webm" type="video/mp4" />
          </video>
        </div>
        <div className="gridFraction">
          <div>
            In diesem Video sehen Sie eine Unterrichtsdemonstration einer Höranalyse zur Exposition der 5. Sinfonie von Ludwig v. Beethoven. Grundlage dieser Höranalyse ist eine einfache Tonreihe/Tonleiter, die zur Musik gesungen wird und als Modell dient, alle gehörten Ereignisse im zeitlich Ablauf zu lokalisieren. Für die Unterrichtsdemonstration haben sich freundlicher Weise Studierende des Hauptseminars "W. A. Mozart und die Sonatenhauptsatzform" (WS 2013) und Prof. Andreas Puhani bereit erklärt.
          </div>
        </div>      
        <div className="gridFraction" style={{ 'width': '320px', 'height': '240px' }}>
          <video controls poster="/data/videos/poster-dur.png">
            <source src="/data/videos/Gb-Dur.webm" type="video/mp4" />
          </video>
        </div>
        <div className="gridFraction">
          <div>
            Eine wichtige Fähigkeit für das Erleben tonaler Musik ist die Fähigkeit, den Charakter eines Tones zum Grundton hören und erkennen zu können. In diesem Video sehen Sie eine Unterrichtsdemonstration zur Gehörbildung in Dur über Zahlen, die sich − ggf. mit einer geringeren Tonauswahl wie z.B. mit fünf oder vier Tönen − sehr effektiv üben lässt. Voraussetzung ist ein wenig Übung im Singen. Für diese Unterrichtsdemonstration haben sich freundlicher Weise Studierende des Hauptseminars "W. A. Mozart und die Sonatenhauptsatzform" (WS 2013) und Prof. Andreas Puhani bereit erklärt.
          </div>
        </div>
        <div className="gridFraction" style={{ 'width': '320px', 'height': '240px' }}>
          <video controls poster="/data/videos/poster-moll.png">
            <source src="/data/videos/Gb-Moll.webm" type="video/mp4" />
          </video>
        </div>
        <div className="gridFraction">
          <div>
            In diesem Video sehen Sie eine Unterrichtsdemonstration zur Gehörbildung in Moll über Zahlen. Für diese Unterrichtsdemonstration haben sich freundlicher Weise Studierende des Hauptseminars "W. A. Mozart und die Sonatenhauptsatzform" (WS 2013) und Prof. Andreas Puhani bereit erklärt.
          </div>
        </div>
        <div className="gridFraction" style={{ 'width': '320px', 'height': '240px' }}> 
          <video controls poster="/data/videos/poster-DasKinderhauslied.png">
            <source src="/data/videos/DasKinderhauslied.webm" type="video/mp4" />
          </video>    
        </div>
        <div className="gridFraction">
          <div>
            In diesem Video sehen Sie die Uraufführung des Kinderhausliedes zum Festgottestdienst des 50jährigen Bestehens des Kinderhauses Sankt Josef.
          </div>
        </div>
        <div className="gridFraction" style={{ 'width': '320px', 'height': '240px' }}>
          <video controls poster="/Images/brahms5.png">
            <source src="https://oer-musik.de/publicfiles/Brahms-UngarTanz5.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="gridFraction">
          <div>
            In diesem Video können Sie einen kleinen Ausschnitt der Uraufführung des Ungarischen Tanzes Nr. 5 von Johannes Brahms durch das Orchester der Musikschule Holzmengen/Hosman in Rumänien sehen. Für dieses Orchester ist das Arrangement geschrieben worden.
          </div>
        </div>
      </div>
      <Helmet>
        <title>OER | Ulrich Kaiser - Videos</title>
        <meta name="description" content="Videos, die im Zusammenhang mit den OpenBooks (OER) von Ulrich Kaiser stehen." />
        <meta name="keywords" content="Videos, OER, Open Educational Resources, OpenBooks" />
        <meta name="copyright" content="Ulrich Kaiser, 2011" />
      </Helmet>
		</Layout>
	)
}